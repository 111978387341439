import React from 'react';

function UpperwareLandingPage() {
  return (
    <div>
      <h1>Welcome to Upperware Studios</h1>
      <p>Hello, world! This is the landing page for Upperware Studios.</p>
      {/* Add more content or sections as needed */}
    </div>
  );
}

export default UpperwareLandingPage;
